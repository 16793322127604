import React from 'react';
import { FormikErrors, FormikTouched } from 'formik';
import { Alert, CircularProgress } from '@mui/material';

import Input from '../ui-kit/Input';
import Button from '../ui-kit/Button';

export interface FormValues {
  email: string;
  password: string;
}

export interface Props {
  values: FormValues;
  touched: FormikTouched<FormValues>;
  errors: FormikErrors<FormValues>;
  loading: boolean;
  generalError: string | string[];
  handleChange(e: React.ChangeEvent<any>): void;
  handleBlur(e: React.FocusEvent<any, Element>): void;
  handleSubmit(e?: React.FormEvent<HTMLFormElement>): void;
}

const LoginForm = ({
  values,
  errors,
  touched,
  loading,
  generalError,
  handleChange,
  handleBlur,
  handleSubmit,
}: Props) => (
  <form className="flex flex-col gap-1 w-full" onSubmit={handleSubmit}>
    {!!generalError && (
      <div className="pb-5">
        <Alert severity="error">{Array.isArray(generalError) ? generalError.at(-1) : generalError}</Alert>
      </div>
    )}
    <Input
      label="Email"
      placeholder="Email"
      name="email"
      autoComplete="off"
      value={values.email}
      error={!!(touched?.email && errors?.email)}
      helperText={touched?.email && errors?.email ? errors.email : ' '}
      onChange={handleChange}
      onBlur={handleBlur}
    />
    <Input
      label="Password"
      placeholder="Password"
      name="password"
      type="password"
      autoComplete="off"
      value={values.password}
      error={!!(touched?.password && errors?.password)}
      helperText={touched?.password && errors?.password ? errors.password : ' '}
      onChange={handleChange}
      onBlur={handleBlur}
    />
    <div className="pt-2 flex justify-center">
      <Button
        type="submit"
        color="secondary"
        disabled={!!(touched?.email && errors?.email) || !!(touched?.password && errors?.password) || loading}
        startIcon={loading ? <CircularProgress size={18} /> : null}
      >
        Login or Register
      </Button>
    </div>
  </form>
);

export default LoginForm;
