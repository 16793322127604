import React from 'react';
import { CircularProgress } from '@mui/material';

import Button from '../ui-kit/Button';
import Dialog, { DialogActions, DialogContent, DialogContentText, DialogTitle } from '../ui-kit/Dialog';

export interface Props {
  open: boolean;
  loading?: boolean;
  title: string;
  msgText?: string;
  cancelBtnLabel?: string;
  submitBtnLabel?: string;
  handleClose(): void;
  handleSubmit(): void;
}

const ConfirmDialog = (props: Props) => {
  const {
    open,
    loading = false,
    title,
    msgText,
    submitBtnLabel = 'Confirm',
    cancelBtnLabel = 'Cancel',
    handleClose,
    handleSubmit,
  } = props;

  return (
    <Dialog open={open} onClose={handleClose}>
      <DialogTitle>{title}</DialogTitle>
      {!!msgText && (
        <DialogContent>
          <DialogContentText className="break-words">{msgText}</DialogContentText>
        </DialogContent>
      )}
      <DialogActions>
        <div className="flex gap-3 pb-5">
          <Button variant="text" color="secondary" onClick={handleClose}>
            {cancelBtnLabel}
          </Button>
          <Button
            color="error"
            disabled={loading}
            startIcon={loading ? <CircularProgress size={18} /> : null}
            onClick={handleSubmit}
          >
            {submitBtnLabel}
          </Button>
        </div>
      </DialogActions>
    </Dialog>
  );
};

export default ConfirmDialog;
