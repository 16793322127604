import { ThemeOptions, createTheme } from '@mui/material/styles';

import palette from './palette';
import typography from './typography';

const theme: ThemeOptions = {
  palette,
  typography,
  components: {
    MuiButton: {
      defaultProps: {
        color: 'primary',
        variant: 'contained',
      },
      styleOverrides: {
        root: {
          fontWeight: 'bold',
          borderRadius: '9999px',
          padding: '6px 20px',
          height: '40px',
        },
        outlined: {
          backdropFilter: 'blur(10px)',
        },
        text: {
          backdropFilter: 'blur(1px)',
        },
      },
    },
    MuiFormControlLabel: {
      styleOverrides: {
        root: {
          color: palette.secondary?.main,
          alignItems: 'flex-start',

          '&.Mui-error': {
            color: '#d32f2f',
          },
        },
        label: {
          paddingLeft: '20px',
        },
      },
    },
    MuiInputLabel: {
      styleOverrides: {
        root: {
          '&:not(.MuiInputLabel-sizeSmall)': {
            transform: 'translate(20px, 16px) scale(1)',
          },
        },
        shrink: {
          '&.MuiInputLabel-sizeMedium, &.MuiInputLabel-sizeSmall': {
            transform: 'translate(20px, -9px) scale(0.75)',
          },
        },
      },
    },
    MuiFormHelperText: {
      styleOverrides: {
        root: {
          marginLeft: '20px',
          marginRight: '20px',
        },
      },
    },
    MuiOutlinedInput: {
      styleOverrides: {
        root: {
          background: '#fff',

          '&:hover:not(.Mui-error) .MuiOutlinedInput-notchedOutline': {
            borderColor: palette.primary?.light,
          },
          '&:not(.borderDefault, .Mui-error, .Mui-focused)': {
            '& .MuiOutlinedInput-notchedOutline': {
              borderColor: 'transparent',
            },
          },
        },
        notchedOutline: {
          '& legend': {
            marginLeft: '5px',
          },
        },
        input: {
          paddingLeft: '20px',
          paddingRight: '20px',
        },
      },
    },
    MuiInputBase: {
      styleOverrides: {
        root: {
          '&.MuiInputBase-formControl': {
            borderRadius: '9999px',
          },
        },
      },
    },
    MuiPaper: {
      styleOverrides: {
        root: {
          background: palette.grey?.[50],
          boxShadow: '0px 2px 4px 0px rgba(0,0,0,0.3)',
        },
      },
    },
    MuiAccordion: {
      styleOverrides: {
        root: {
          background: palette.grey?.[50],
          boxShadow: '0px 2px 4px 0px rgba(0,0,0,0.3)',
        },
      },
    },
    MuiAccordionSummary: {
      styleOverrides: {
        root: {
          borderBottomRightRadius: '4px',
          borderBottomLeftRadius: '4px',
          boxShadow: '0px 1px 1px 0px rgba(0,0,0,.2)',
          // borderBottom: `1px solid ${palette.grey?.[400]}`,
        },
        content: {
          minWidth: 0,

          '&, &.Mui-expanded': {
            margin: '8px 0',
          },
        },
      },
    },
    MuiDialog: {
      styleOverrides: {
        paper: {
          marginLeft: '16px',
          marginRight: '16px',
        },
      },
    },
    MuiDialogActions: {
      styleOverrides: {
        root: {
          paddingLeft: '24px',
          paddingRight: '24px',
        },
      },
    },
    MuiList: {
      styleOverrides: {
        root: {
          '& .MuiMenuItem-root.Mui-selected': {
            background: 'rgba(255, 219, 115, 0.4)',

            '&:hover': {
              background: 'rgba(255, 219, 115, 0.5)',
            },
          },
        },
      },
    },
    MuiSnackbar: {
      styleOverrides: {
        root: {
          '&.MuiSnackbar-anchorOriginBottomCenter': {
            bottom: '80px',
          },
        },
      },
    },
  },
};

export default createTheme(theme);
