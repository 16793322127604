import React, { useMemo } from 'react';
import ClearRoundedIcon from '@mui/icons-material/ClearRounded';
import dayjs, { Dayjs } from 'dayjs';
import { FieldArrayRenderProps, FormikErrors, FormikTouched } from 'formik';

import dateMask, { fixedDates } from '../../utils/date';
import { selectRenderValue } from '../ui-kit/Input';
import Select from '../ui-kit/Select';
import MenuItem from '../ui-kit/MenuItem';
import DatePicker, { getActionBar } from '../ui-kit/DatePicker';
import IconButton from '../ui-kit/Button/IconButton';

export interface OccasionValue {
  label: string;
  date: Dayjs | null;
}

export interface Props {
  label: OccasionValue['label'];
  date: OccasionValue['date'];
  arrayHelpers: FieldArrayRenderProps;
  index: number;
  touched: FormikTouched<OccasionValue>;
  errors: FormikErrors<OccasionValue> | string;
  selectedOccasions: OccasionValue[];
  occasionTypes: string[];
  showDelete: boolean;
  handleChange(e: React.ChangeEvent<any>): void;
  handleBlur(e: React.FocusEvent<any, Element>): void;
  handleDateChange(value: Dayjs | null, context?: any): void;
}

const OccasionFormFields = (props: Props) => {
  const {
    label,
    date,
    index,
    arrayHelpers,
    errors,
    touched,
    occasionTypes,
    selectedOccasions,
    showDelete,
    handleChange,
    handleBlur,
    handleDateChange,
  } = props;

  const isError = (fieldName: keyof OccasionValue): boolean => {
    if (touched?.[fieldName]) {
      return typeof errors === 'string' ? !!errors : !!errors?.[fieldName];
    }

    return false;
  };

  const getErrorMsg = (fieldName: keyof OccasionValue): string => {
    if (isError(fieldName)) {
      return typeof errors === 'string' ? errors : errors?.[fieldName] || ' ';
    }

    return ' ';
  };

  const onChange = (e: React.ChangeEvent<any>) => {
    handleChange(e);

    if (fixedDates.has(e.target.value)) {
      handleDateChange(fixedDates.get(e.target.value));
    }
  };

  const availableOccasions = useMemo((): string[] => {
    return occasionTypes.filter(occasionType => {
      const selectedIndex = selectedOccasions.findIndex(
        ({ label: selectedOccasion }) => selectedOccasion === occasionType,
      );
      return selectedIndex === -1 || selectedIndex === index;
    });
  }, [index, occasionTypes, selectedOccasions]);

  return (
    <div className="flex gap-4">
      <div className="flex flex-col grow gap-1">
        <Select
          select
          label={`Occasion Type ${!!label || !!date ? '*' : ''}`}
          name={`occasions.${index}.label`}
          autoComplete="off"
          value={label}
          error={isError('label')}
          helperText={getErrorMsg('label')}
          SelectProps={{
            displayEmpty: true,
            renderValue: selectRenderValue(`Occasion Type ${!!label || !!date ? '*' : ''}`),
          }}
          onChange={onChange}
          onBlur={handleBlur}
        >
          <MenuItem hidden className="!hidden" value="" />
          {availableOccasions.map(occasion => (
            <MenuItem key={occasion} value={occasion}>
              {occasion}
            </MenuItem>
          ))}
        </Select>
        <DatePicker
          closeOnSelect
          label={`Occasion Date ${!!label || !!date ? '*' : ''}`}
          name={`occasions.${index}.date`}
          format={dateMask}
          value={date}
          minDate={dayjs().subtract(100, 'year')}
          maxDate={dayjs().add(5, 'year')}
          slots={{
            actionBar: getActionBar({ value: date }),
          }}
          slotProps={{
            textField: {
              placeholder: `Occasion Date ${!!label || !!date ? '*' : ''}`,
              error: isError('date'),
              helperText: getErrorMsg('date'),
              onKeyDown: e => e.preventDefault(),
            },
          }}
          onAccept={handleDateChange}
          onClose={() => handleBlur({ target: { name: `occasions.${index}.date` } } as any)}
        />
      </div>
      <div className="w-[51px] pt-[24px]">
        {showDelete && (
          <IconButton onClick={() => arrayHelpers.remove(index)}>
            <ClearRoundedIcon fontSize="large" />
          </IconButton>
        )}
      </div>
    </div>
  );
};

export default OccasionFormFields;
