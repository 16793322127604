import { API_BASE_URL } from '../../config/api';
import fetch from '../../utils/fetch';

export interface DropdownsData {
  gender: string[];
  countries: string[];
  hobbies: string[];
  interests: string[];
  occasions: string[];
}

export const getDropdowns = () => fetch<DropdownsData>(`${API_BASE_URL}/dropdowns`, { method: 'GET' });
