import { useState, useEffect, useCallback } from 'react';

import Profile, { ProfileUpdateDto } from '../../interfaces/Profile';
import { login, loginGuest, getProfile, updateProfile, LoginPayload } from './api';

export interface AuthHook {
  loading: boolean;
  initialized: boolean;
  accessToken: string | null;
  profile: Profile | null;
  handleLogin(data: LoginPayload, throwError?: boolean): Promise<Profile | null>;
  handleLoginGuest(throwError?: boolean): Promise<Profile | null>;
  handleLogout(): Promise<void>;
  handleUpdateProfile(data: ProfileUpdateDto, throwError?: boolean): Promise<void>;
}

const useAuth = (): AuthHook => {
  const [initialized, setInitialized] = useState(false);
  const [loading, setLoading] = useState(false);
  const [profile, setProfile] = useState<Profile | null>(null);
  const [accessToken, setAccessToken] = useState<string | null>(localStorage.getItem('accessToken') || null);

  const handleSetAccessToken = useCallback(
    (token: string | null) => {
      setAccessToken(token);
      if (token) {
        localStorage.setItem('accessToken', token);
      } else {
        localStorage.removeItem('accessToken');
      }
    },
    [setAccessToken, localStorage.setItem, localStorage.removeItem],
  );

  const handleLogout = useCallback(async () => handleSetAccessToken(null), [handleSetAccessToken]);

  const loadProfile = useCallback(
    async (token: string, throwError = true): Promise<Profile | null> => {
      let result = null;
      setLoading(true);
      try {
        const { data } = await getProfile({ accessToken: token, handleLogout });
        setProfile(data);
        result = data;
      } catch (err) {
        if (throwError) {
          throw err;
        }
      } finally {
        setLoading(false);
      }

      return result;
    },
    [setLoading, setProfile, getProfile, handleLogout],
  );

  const handleUpdateProfile = useCallback(
    async (payload: Profile, throwError = true) => {
      setLoading(true);
      try {
        const { data } = await updateProfile({ accessToken, handleLogout }, payload);
        setProfile(data);
      } catch (err) {
        if (throwError) {
          throw err;
        }
      } finally {
        setLoading(false);
      }
    },
    [setLoading, setProfile, updateProfile, accessToken, handleLogout],
  );

  const handleLogin = useCallback(
    async (payload: LoginPayload, throwError = true): Promise<Profile | null> => {
      let result = null;
      setLoading(true);
      try {
        const { data } = await login(payload);
        const prof = await loadProfile(data.accessToken);
        handleSetAccessToken(data.accessToken);
        result = prof;
      } catch (err) {
        if (throwError) {
          throw err;
        }
      } finally {
        setLoading(false);
      }

      return result;
    },
    [setLoading, handleSetAccessToken, loadProfile, login],
  );

  const handleLoginGuest = useCallback(
    async (throwError?: boolean): Promise<Profile | null> => {
      let result = null;
      setLoading(true);
      try {
        const { data } = await loginGuest();
        const prof = await loadProfile(data.accessToken);
        handleSetAccessToken(data.accessToken);
        result = prof;
      } catch (err) {
        if (throwError) {
          throw err;
        }
      } finally {
        setLoading(false);
      }

      return result;
    },
    [setLoading, handleSetAccessToken, loadProfile, loginGuest],
  );

  useEffect(() => {
    if (accessToken && !profile) {
      loadProfile(accessToken, false).finally(() => {
        setInitialized(true);
      });
    } else {
      setInitialized(true);
    }
  }, []);

  return {
    loading,
    initialized,
    accessToken,
    profile,
    handleLogin,
    handleLoginGuest,
    handleLogout,
    handleUpdateProfile,
  };
};

export default useAuth;
