import React from 'react';
import { useNavigate, useParams, useSearchParams, Navigate } from 'react-router-dom';
import { Alert, CircularProgress, Paper, Skeleton, Typography } from '@mui/material';

import Budget from '../../config/budget';
import Button from '../../components/ui-kit/Button';
import Footer from '../../components/Footer';
import useContacts from '../../data-hooks/contacts/useContacts';
import useRecommendations from '../../data-hooks/recommendations/useRecommendations';

const Recommendations: React.FC = () => {
  const navigate = useNavigate();
  const { id: connectionId } = useParams();
  const [queryParams] = useSearchParams();

  const queryOccasionId = queryParams.get('occasionId') || '';
  let budget = Budget.MAX;
  if (queryParams.get('budget')) {
    budget = Number.parseFloat(queryParams.get('budget') || '');
    if (budget < Budget.MIN) {
      budget = Budget.MIN;
    }
    if (budget > Budget.MAX) {
      budget = Budget.MAX;
    }
  }

  const { selected: connection, loading: connectionLoading } = useContacts({ fetchById: connectionId });
  const {
    data: recommends,
    reasons,
    loading: recommendsLoading,
    error,
  } = useRecommendations({ occasionId: queryOccasionId, budget });

  const occasion = connection?.occasions?.find(({ id: occasionId }) => occasionId === queryOccasionId);

  const moveBack = () => {
    navigate('/');
  };

  const formatPrice = (price: string): string => {
    let priceSymbol: string;
    if (price.includes('€')) {
      priceSymbol = '€';
    } else {
      priceSymbol = '$';
    }

    return price.split(priceSymbol).reduce((res, item, i) => {
      return item?.length ? `${res} ${i > 0 && res.length ? '/' : ''} ${item.trim()}${priceSymbol}` : res;
    }, '');
  };

  if (!queryOccasionId || !budget || (!connectionLoading && !occasion)) {
    return <Navigate replace to="/" />;
  }

  return (
    <div className="flex flex-col grow">
      <div
        className="!h-[50px] text-white !bg-[length:100%_100%]"
        style={{ background: 'url("/section-curve.png") no-repeat' }}
      />
      <div className="grow flex bg-white pb-12">
        <div className="container min-h-full flex flex-col">
          <div className="min-h-[48px] mb-3">
            <Typography variant="h5" className="text-center text-blue-light">
              Recommendations for
              <br />
              {!!connection && <span className="!font-bold !break-words">{connection?.name}</span>}
            </Typography>
          </div>
          <Typography className="text-blue-light text-center mb-3">We are Amazon Affiliate Partners</Typography>
          {connectionLoading && !connection && (
            <div className="flex justify-center items-center h-[40vh]">
              <CircularProgress size={40} />
            </div>
          )}
          {!!connection && (
            <div className="pb-3">
              <div className="pt-5">
                {!!error && (
                  <div>
                    <Alert severity="error">Something went wrong. Try again later, please.</Alert>
                  </div>
                )}
                {!recommends?.length && !recommendsLoading && !error && (
                  <div>
                    <Alert severity="info">No recommendations. Try again later, please.</Alert>
                  </div>
                )}
                {!recommends?.length && recommendsLoading && (
                  <div className="flex justify-center items-center py-10">
                    <CircularProgress size={40} />
                  </div>
                )}
                {!!recommends?.length &&
                  recommends.map(({ id, title, originalTitle, image, link, price }) => (
                    <Paper key={`${link}-${title}`} className="mb-3">
                      <a href={link} target="_blank" rel="noreferrer">
                        <div className="p-3 flex gap-2">
                          <div className="basis-1/4 shrink-0">
                            <img src={image} alt={title} className="max-w-full max-h-full" />
                          </div>
                          <div className="w-full flex flex-col basis-3/4 max-w-[75%] justify-between">
                            <div>
                              <Typography className="!font-bold !text-[14px] !leading-tight !break-words">
                                {title || originalTitle}
                              </Typography>
                              {!reasons?.[id] && recommendsLoading && (
                                <div className="w-full py-2">
                                  <Skeleton variant="text" width="full" />
                                  <Skeleton variant="text" width="full" />
                                </div>
                              )}
                              {!!reasons?.[id] && !recommendsLoading && (
                                <div className="w-full py-1">
                                  <Typography className="!text-[14px] !leading-tight !break-words">
                                    {reasons[id]}
                                  </Typography>
                                </div>
                              )}
                            </div>
                            <div className="flex items-center justify-between gap-5 pt-4">
                              <Typography>{!!price && formatPrice(price)}</Typography>
                              <Button>Open</Button>
                            </div>
                          </div>
                        </div>
                      </a>
                    </Paper>
                  ))}
              </div>
            </div>
          )}
          <div className="pb-10 mt-auto">
            <Footer />
          </div>
          <Button className="!fixed bottom-7 left-[50%] -translate-x-1/2 w-max" onClick={moveBack}>
            Back to Connections
          </Button>
        </div>
      </div>
    </div>
  );
};

export default Recommendations;
