import { API_BASE_URL } from '../../config/api';
import fetch, { fetchWithAuth, Auth } from '../../utils/fetch';
import Profile from '../../interfaces/Profile';

export interface LoginPayload {
  email: string;
  password: string;
}

export const login = (data: LoginPayload) =>
  fetch<{ accessToken: string }>(`${API_BASE_URL}/auth/login`, { method: 'POST', data });

export const loginGuest = () => fetch<{ accessToken: string }>(`${API_BASE_URL}/auth/guest`, { method: 'POST' });

export const getProfile = (auth: Auth) =>
  fetchWithAuth<Profile>(auth, `${API_BASE_URL}/auth/profile`, { method: 'GET' });

export const updateProfile = (auth: Auth, data: Profile) =>
  fetchWithAuth<Profile>(auth, `${API_BASE_URL}/users`, { method: 'PUT', data });
