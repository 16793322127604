import React, { PropsWithChildren } from 'react';
import { Navigate } from 'react-router-dom';

import { useAuth } from '../../providers/auth';

const PrivateRoute: React.FC<PropsWithChildren> = ({ children }: PropsWithChildren) => {
  const { accessToken } = useAuth();

  if (!accessToken) {
    return <Navigate replace to="/login" />;
  }

  return <div>{children}</div>;
};

export default PrivateRoute;
