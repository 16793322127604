import React from 'react';
import { createBrowserRouter, Navigate, Outlet } from 'react-router-dom';

import PrivateRoute from '../components/PrivateRoute';
import EnsureProfile from '../components/EnsureProfile';
import MainLayout from '../components/MainLayout';
import Login from '../pages/Login';
import Profile from '../pages/Profile';
import Connections from '../pages/Connections';
import Recommendations from '../pages/Recommendations';
import Notifications from '../pages/Notifications';
import NewConnection from '../pages/NewConnection';

const routes = createBrowserRouter([
  {
    path: '',
    element: (
      <PrivateRoute>
        <MainLayout>
          <Outlet />
        </MainLayout>
      </PrivateRoute>
    ),
    children: [
      {
        path: '',
        element: (
          <EnsureProfile>
            <Outlet />
          </EnsureProfile>
        ),
        children: [
          {
            index: true,
            element: <Connections />,
          },
          {
            path: 'connections/new',
            element: <NewConnection />,
          },
          {
            path: 'connections/:id/edit',
            element: <NewConnection />,
          },
          {
            path: 'connections/:id',
            element: <Recommendations />,
          },
          {
            path: 'notifications',
            element: <Notifications />,
          },
        ],
      },
      {
        path: 'profile',
        element: <Profile />,
      },
    ],
  },
  {
    path: 'login',
    element: <Login />,
  },
  {
    path: '*',
    element: <Navigate replace to="/" />,
  },
]);

export default routes;
