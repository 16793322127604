import { useState, useEffect } from 'react';

import Notification from '../../interfaces/Notification';
import { useAuth } from '../../providers/auth';
import getNotifications from './api';

export interface NotificationHook {
  loading: boolean;
  data: Notification[];
  handleFetch(throwError?: boolean): Promise<void>;
}

const useNotification = ({ fetchAll }: { fetchAll?: boolean } = {}): NotificationHook => {
  const auth = useAuth();

  const [loading, setLoading] = useState<boolean>(fetchAll || false);
  const [data, setData] = useState<NotificationHook['data']>([]);

  const handleFetch = async (throwError?: boolean) => {
    setLoading(true);
    try {
      const { data: notifications } = await getNotifications(auth);
      setData(notifications);
    } catch (err) {
      if (throwError) {
        throw err;
      }
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (fetchAll) {
      handleFetch();
    }
  }, [fetchAll]);

  return {
    data,
    loading,
    handleFetch,
  };
};

export default useNotification;
