import React from 'react';

export interface Props {
  color?: 'primary' | 'gray';
}

const Logo: React.FC<Props> = () => (
  <div className="flex justify-center">
    <img src="/logo_b_1.svg" alt="OFFRA" className="aspect-[300/69]" />
  </div>
);

export default Logo;
