import React, { useState } from 'react';
import { Navigate } from 'react-router-dom';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { AxiosError } from 'axios';

import { Occasion, ProfileUpdateDto } from '../../interfaces/Profile';
import { useAuth } from '../../providers/auth';
import useDropdowns from '../../data-hooks/profile-dropdowns/useDropdowns';
import ProfileForm, { profileFormValidators, ProfileValues } from '../../components/ProfileForm';
import Footer from '../../components/Footer';

const initialValues: ProfileValues = {
  name: '',
  gender: '',
  birthday: null,
  country: '',
  hobbies: [],
  interests: [],
  occasions: [
    {
      label: '',
      date: null,
    },
  ],
};

const validationSchema = Yup.object<ProfileValues>().shape(profileFormValidators);

const Profile: React.FC = () => {
  const [generalError, setGeneralError] = useState<string>('');
  const { accessToken, profile, loading, handleUpdateProfile } = useAuth();
  const { dropdowns } = useDropdowns();

  const formikState = useFormik<ProfileValues>({
    initialValues,
    validationSchema,
    validateOnChange: true,
    onSubmit: async (val: ProfileValues) => {
      const bYear = val.birthday?.get('year');
      const bMonth = (val.birthday?.get('month') || 0) + 1;
      const bDate = val.birthday?.get('date') || 0;

      const data: ProfileUpdateDto = {
        ...profile,
        ...val,
        id: profile!.id,
        email: profile!.email,
        birthday: `${bYear}-${bMonth >= 10 ? bMonth : `0${bMonth}`}-${bDate >= 10 ? bDate : `0${bDate}`}T00:00:00.000Z`,
        occasions: val.occasions.reduce<Pick<Occasion, 'name' | 'date'>[]>(
          (res: Pick<Occasion, 'name' | 'date'>[], { label, date }) => {
            if (label && date) {
              const oYear = date.get('year');
              const oMonth = date.get('month') + 1;
              const oDate = date.get('date');

              return [
                ...res,
                {
                  name: label,
                  date: `${oYear}-${oMonth >= 10 ? oMonth : `0${oMonth}`}-${oDate >= 10 ? oDate : `0${oDate}`}T00:00:00.000Z`,
                },
              ];
            }

            return res;
          },
          [],
        ),
      };
      if (!data.occasions?.length) {
        delete data.occasions;
      }

      try {
        return await handleUpdateProfile(data, true);
      } catch (err) {
        window.scrollTo(0, 0);

        if (err instanceof AxiosError && err.response?.data?.message) {
          return setGeneralError(
            Array.isArray(err.response.data.message) ? err.response.data.message.at(-1) : err.response.data.message,
          );
        }

        return setGeneralError('Something went wrong. Try again later, please.');
      }
    },
  });
  const { values, errors, touched, isValid, submitCount, handleSubmit, handleChange, handleBlur } = formikState;

  if (!loading && accessToken && (profile?.name || profile?.type === 'guest')) {
    return <Navigate replace to="/" />;
  }

  return (
    <div className="container pt-5 pb-10">
      <ProfileForm
        title="Create my profile"
        showOccasionFields={false}
        values={values}
        errors={errors}
        touched={touched}
        loading={loading}
        dropdowns={dropdowns}
        isValid={submitCount === 0 || isValid}
        formikState={formikState}
        generalError={generalError}
        handleSubmit={handleSubmit}
        handleChange={handleChange}
        handleBlur={handleBlur}
      />
      <div className="pt-3">
        <Footer />
      </div>
    </div>
  );
};

export default Profile;
