import React, { useState } from 'react';
import { Typography } from '@mui/material';

import Button from '../ui-kit/Button';

const COOKIE_ACCEPTED_KEY = 'cookieAccepted';
const ConfirmCookieDialog: React.FC = () => {
  const accepted = JSON.parse(localStorage.getItem(COOKIE_ACCEPTED_KEY) || '{}');
  const [show, setShow] = useState<boolean>(typeof accepted === 'boolean' ? !accepted : true);

  const accept = () => {
    setShow(false);
    localStorage.setItem(COOKIE_ACCEPTED_KEY, JSON.stringify(true));
  };

  if (!show) {
    return null;
  }

  return (
    <div className="fixed bottom-0 left-0 right-0 bg-[#FFF2C1] shadow-[0px_-1px_10px_-1px_rgba(0,0,0,.3)] z-30 p-2 pb-4 sm:p-4">
      <div>
        <Typography className="!text-md text-blue-main">
          We may use cookies to enhance your browsing experience, serve personalized ads or content, and analyze our
          traffic. By continuing to use our site, you consent to our use of cookies.
        </Typography>
      </div>
      <div className="flex justify-end pt-2">
        <Button color="secondary" onClick={accept}>
          Accept
        </Button>
      </div>
    </div>
  );
};

export default ConfirmCookieDialog;
