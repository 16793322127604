import React, { useState, useEffect } from 'react';
import { useNavigate, useParams, Navigate } from 'react-router-dom';
import { CircularProgress } from '@mui/material';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { AxiosError } from 'axios';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';

import { ContactCreateDTO } from '../../interfaces/Contact';
import ProfileForm, { profileFormValidators, ProfileValues } from '../../components/ProfileForm';
import Footer from '../../components/Footer';
import useContacts from '../../data-hooks/contacts/useContacts';
import useDropdowns from '../../data-hooks/profile-dropdowns/useDropdowns';

dayjs.extend(utc);

const initialValues: ProfileValues = {
  name: '',
  gender: '',
  birthday: null,
  country: '',
  hobbies: [],
  interests: [],
  occasions: [
    {
      label: '',
      date: null,
    },
  ],
};

const NewConnection: React.FC = () => {
  const navigate = useNavigate();
  const { id } = useParams();

  const [generalError, setGeneralError] = useState<string>('');
  const {
    selected,
    data: connections,
    loading,
    handleCreate,
    handleUpdate,
  } = useContacts({ fetchAll: true, fetchById: id });
  const { dropdowns, loading: loadingDropdowns } = useDropdowns();

  const formikState = useFormik<ProfileValues>({
    initialValues,
    validationSchema: Yup.object<ProfileValues>().shape({
      ...profileFormValidators,
      name: profileFormValidators.name.test(
        'Duplicate name',
        'Connection with this name already exists',
        value => !connections.find(({ id: connId, name }) => name === value && connId !== selected?.id),
      ),
    }),
    validateOnChange: true,
    onSubmit: async (val: ProfileValues) => {
      const bYear = val.birthday?.get('year');
      const bMonth = (val.birthday?.get('month') || 0) + 1;
      const bDate = val.birthday?.get('date') || 0;

      const data: ContactCreateDTO = {
        ...val,
        birthday: `${bYear}-${bMonth >= 10 ? bMonth : `0${bMonth}`}-${bDate >= 10 ? bDate : `0${bDate}`}T00:00:00.000Z`,
        occasions: val.occasions.reduce<ContactCreateDTO['occasions']>(
          (res: ContactCreateDTO['occasions'], { label, date }) => {
            if (label && date) {
              const oYear = date.get('year');
              const oMonth = date.get('month') + 1;
              const oDate = date.get('date');
              return [
                ...(res || []),
                {
                  name: label,
                  date: `${oYear}-${oMonth >= 10 ? oMonth : `0${oMonth}`}-${oDate >= 10 ? oDate : `0${oDate}`}T00:00:00.000Z`,
                },
              ];
            }

            return res;
          },
          [],
        ),
      };
      if (!data.occasions?.length) {
        delete data.occasions;
      }

      try {
        if (id) {
          await handleUpdate(id, data, true);
        } else {
          await handleCreate(data, true);
        }
        return navigate('/');
      } catch (err) {
        window.scrollTo(0, 0);

        if (err instanceof AxiosError && err.response?.data?.message) {
          return setGeneralError(
            Array.isArray(err.response.data.message) ? err.response.data.message.at(-1) : err.response.data.message,
          );
        }

        return setGeneralError('Something went wrong. Try again later, please.');
      }
    },
  });

  const { values, errors, touched, isValid, submitCount, handleSubmit, handleChange, handleBlur } = formikState;

  useEffect(() => {
    if (id && !loading && selected && connections?.length) {
      const { name, gender, birthday, country, hobbies, interests, occasions } = selected;

      let occasionsVal: ProfileValues['occasions'] = [];
      if (occasions?.length) {
        occasionsVal = occasions.reduce<ProfileValues['occasions']>(
          (acc: ProfileValues['occasions'], { name: occasionName, date }) => {
            if (occasionName.toLowerCase() === 'no occasion') {
              return acc;
            }

            return [...acc, { label: occasionName, date: date ? dayjs(date) : null }];
          },
          [],
        );
      }

      formikState.resetForm({
        values: {
          name,
          gender,
          birthday: dayjs(birthday),
          country,
          hobbies,
          interests,
          occasions: occasionsVal,
        },
      });
    }
  }, [selected, loading, connections]);

  if (id && (loading || loadingDropdowns || !selected || !connections?.length)) {
    return (
      <div className="flex justify-center items-center h-[40vh]">
        <CircularProgress size={40} />
      </div>
    );
  }

  if (id && !loading && !selected) {
    return <Navigate to="/connections/new" replace />;
  }

  return (
    <div className="container pb-10">
      <ProfileForm
        title={id ? 'Update connection' : 'Create a connection'}
        submitBtnLabel={id ? 'Update' : 'Create'}
        values={values}
        errors={errors}
        touched={touched}
        loading={loading}
        dropdowns={dropdowns}
        isValid={submitCount === 0 || isValid}
        formikState={formikState}
        generalError={generalError}
        handleSubmit={handleSubmit}
        handleChange={handleChange}
        handleBlur={handleBlur}
      />
      <div className="pt-3">
        <Footer />
      </div>
    </div>
  );
};

export default NewConnection;
