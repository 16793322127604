import { useState, useEffect } from 'react';
import { getDropdowns, DropdownsData } from './api';

const initialValue: DropdownsData = {
  gender: [],
  countries: [],
  hobbies: [],
  interests: [],
  occasions: [],
};

const useDropdowns = (autoFetch = true) => {
  const [dropdowns, setDropdowns] = useState(initialValue);
  const [loading, setLoading] = useState(false);

  const fetch = async () => {
    setLoading(true);
    try {
      const { data } = await getDropdowns();
      setDropdowns(data);
    } catch (err) {
      /* eslint-disable-next-line no-console */
      console.log(err);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (autoFetch) {
      fetch();
    }
  }, [autoFetch]);

  return {
    dropdowns,
    loading,
    fetch,
  };
};

export default useDropdowns;
