import React, { MouseEvent, useState, useRef, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { CircularProgress, Typography } from '@mui/material';
import DeleteForeverRoundedIcon from '@mui/icons-material/DeleteForeverRounded';
import ExpandMoreRoundedIcon from '@mui/icons-material/ExpandMoreRounded';
import CheckRoundedIcon from '@mui/icons-material/CheckRounded';
import EditRoundedIcon from '@mui/icons-material/EditRounded';
import PersonAddAlt1RoundedIcon from '@mui/icons-material/PersonAddAlt1Rounded';
import dayjs from 'dayjs';

import Budget from '../../config/budget';
import isBudgetValid from '../../utils';
import Contact from '../../interfaces/Contact';
import { Occasion } from '../../interfaces/Profile';
import Button from '../../components/ui-kit/Button';
import IconButton from '../../components/ui-kit/Button/IconButton';
import Accordion, { AccordionSummary, AccordionDetails } from '../../components/ui-kit/Accordion';
import useContacts from '../../data-hooks/contacts/useContacts';
import ConfirmDialog from '../../components/ConfirmDialog';
import Input from '../../components/ui-kit/Input';
import Footer from '../../components/Footer';
import ShareButton from '../../components/ShareButton';

const Connections: React.FC = () => {
  const navigate = useNavigate();
  const { data, loading, handleDelete, handleFetch } = useContacts({ fetchAll: true });
  const [confirmDelete, setConfirmDelete] = useState<{ id: string; name: string } | null>(null);
  const [showBudgetField, setShowBudgetField] = useState<string>('');
  const [budgetValue, setBudgetValue] = useState<string>('');

  const budgetInpRef = useRef<HTMLInputElement>();

  useEffect(() => {
    if (showBudgetField) {
      budgetInpRef.current?.focus?.();
    }
  }, [showBudgetField]);

  const deleteConnection = async (
    connection: Pick<Contact, 'id' | 'name'>,
    confirmed?: boolean,
    e?: MouseEvent<HTMLButtonElement>,
  ) => {
    e?.preventDefault();
    e?.stopPropagation();

    if (confirmed) {
      await handleDelete(connection.id);
      await handleFetch();
      setConfirmDelete(null);
      return;
    }

    setConfirmDelete(connection);
  };

  const getDateLabel = (occasion: Occasion): string => {
    const now = dayjs().toISOString().split('T')[0];
    const today = dayjs(`${now}T00:00:00.000Z`);
    let date = dayjs(occasion.date);
    let diff = date.diff(today, 'days');

    if (diff === 0) {
      return 'today';
    }

    if (diff < 0 && !occasion.yearly) {
      diff *= -1;
      return `was ${diff} day${diff > 1 ? 's' : ''} ago`;
    }

    while (diff < 0) {
      date = date.add(1, 'year');
      diff = date.diff(today, 'days');
    }

    if (diff === 0) {
      return 'today';
    }

    return `in ${diff} day${diff > 1 ? 's' : ''}`;
  };

  const handleSetBudgetValue = (value: string): void => {
    setBudgetValue(value);
  };

  const openRecommends = (connectionId: string, occasionId: string) => {
    if (isBudgetValid(budgetValue)) {
      const budget = Number.parseFloat(budgetValue).toFixed(2);
      navigate(`/connections/${connectionId}?occasionId=${occasionId}&budget=${budget}`);
    }
  };

  return (
    <div className="flex flex-col grow">
      <div
        className="!h-[50px] text-white !bg-[length:100%_100%]"
        style={{ background: 'url("/section-curve.png") no-repeat' }}
      />
      <div className="flex grow bg-white pb-10">
        <div className="container min-h-full flex flex-col">
          <div className="mb-8 flex gap-2 justify-center items-center">
            <Button
              variant="outlined"
              size="large"
              color="secondary"
              className="!w-[48px] !min-w-[48px] !h-[48px] !p-1"
              onClick={() => navigate('/connections/new')}
            >
              <PersonAddAlt1RoundedIcon fontSize="medium" />
            </Button>
            <Typography variant="h5" className="text-center text-blue-light">
              Connections
            </Typography>
          </div>
          <div>
            {!data?.length && loading && (
              <div className="flex justify-center items-center">
                <CircularProgress size={40} />
              </div>
            )}
            {!!data?.length &&
              data.map(({ id, name, occasions }) => (
                <div key={id} className="mb-3">
                  <Accordion defaultExpanded>
                    <AccordionSummary expandIcon={<ExpandMoreRoundedIcon />}>
                      <div className="flex justify-between items-center gap-2 w-full">
                        <Typography className="!font-bold truncate">{name}</Typography>
                        <div className="flex gap-2">
                          <IconButton size="large" onClick={() => navigate(`/connections/${id}/edit`)}>
                            <EditRoundedIcon />
                          </IconButton>
                          <IconButton size="large" onClick={e => deleteConnection({ id, name }, false, e)}>
                            <DeleteForeverRoundedIcon />
                          </IconButton>
                        </div>
                      </div>
                    </AccordionSummary>
                    <AccordionDetails>
                      <div className="pt-3">
                        {!!occasions?.length &&
                          occasions.map(({ id: occasionId, name: occasionName, date, ...occasion }) => (
                            <div
                              key={occasionId}
                              className="flex flex-wrap sm:flex-nowrap justify-between items-start mb-3 sm:mb-1 min-h-[64px]"
                            >
                              <div className="flex flex-wrap sm:min-h-[40px] items-center">
                                <Typography className="leading-none !mb-1 sm:mb-0 !mr-1">
                                  {occasionName.toLowerCase() === 'no occasion'
                                    ? 'Gift without Occasion'
                                    : occasionName}
                                </Typography>
                                {date && (
                                  <Typography className="leading-none italic !text-[12px]">
                                    {getDateLabel({ id: occasionId, name: occasionName, date, ...occasion })}
                                  </Typography>
                                )}
                              </div>
                              <div className="shrink-0 ml-auto">
                                {occasionId === showBudgetField ? (
                                  <form
                                    onSubmit={e => {
                                      e.preventDefault();
                                      e.stopPropagation();
                                      openRecommends(id, occasionId);
                                    }}
                                  >
                                    <div className="flex gap-1">
                                      <Input
                                        animatedLabel="Budget"
                                        name="budget"
                                        type="number"
                                        autoComplete="off"
                                        size="small"
                                        className="w-32"
                                        helperText={`Min ${Budget.MIN}, Max ${Budget.MAX}`}
                                        FormHelperTextProps={{ style: { margin: '0 10px' } }}
                                        ref={budgetInpRef}
                                        value={budgetValue}
                                        onChange={({ target }) => handleSetBudgetValue(target.value)}
                                      />
                                      <Button
                                        type="submit"
                                        className="!w-[40px] !min-w-[40px] !h-[40px] !p-1"
                                        disabled={!isBudgetValid(budgetValue)}
                                      >
                                        <CheckRoundedIcon />
                                      </Button>
                                    </div>
                                  </form>
                                ) : (
                                  <Button
                                    onClick={() => {
                                      setShowBudgetField(occasionId);
                                      setBudgetValue('');
                                    }}
                                  >
                                    Set Budget
                                  </Button>
                                )}
                              </div>
                            </div>
                          ))}
                      </div>
                    </AccordionDetails>
                  </Accordion>
                </div>
              ))}
          </div>
          <div className="pb-14">
            <Footer />
          </div>
          <ShareButton className="!fixed bottom-7 left-[50%] -translate-x-1/2 w-max">Share OFFRA</ShareButton>
        </div>
        {!!confirmDelete && (
          <ConfirmDialog
            open={!!confirmDelete}
            loading={loading}
            title="Confirm Deletion"
            msgText={`Are you sure you want to delete connection ${confirmDelete?.name}?`}
            handleClose={() => setConfirmDelete(null)}
            handleSubmit={() => deleteConnection(confirmDelete, true)}
          />
        )}
      </div>
    </div>
  );
};

export default Connections;
