import React, { PropsWithChildren } from 'react';
import { Navigate } from 'react-router-dom';
import { CircularProgress } from '@mui/material';

import { useAuth } from '../../providers/auth';

const EnsureProfile: React.FC<PropsWithChildren> = ({ children }: PropsWithChildren) => {
  const { profile, loading, initialized } = useAuth();

  if (!initialized || loading) {
    return (
      <div className="flex justify-center py-[40vh]">
        <CircularProgress size={40} />
      </div>
    );
  }

  if (initialized && !loading && !profile?.name && profile?.type !== 'guest') {
    return <Navigate replace to="/profile" />;
  }

  /* eslint-disable-next-line react/jsx-no-useless-fragment */
  return <>{children}</>;
};

export default EnsureProfile;
