import React, { useState, forwardRef, ForwardedRef, Dispatch } from 'react';
import { TextFieldProps } from '@mui/material/TextField';

import Input from '../Input';
import Button from '../Button';

const CloseButton = ({ setOpen }: { setOpen: Dispatch<boolean> }) => (
  <div className="sticky bottom-0 left-0 right-0 px-4 py-3 mt-2 flex justify-end bg-grey-50 shadow-[0_-1px_3px_-1px_rgba(0,0,0,0.2)]">
    <Button type="button" onClick={() => setOpen(false)}>
      Close
    </Button>
  </div>
);

const Select = forwardRef(({ SelectProps, children, ...props }: TextFieldProps, ref?: ForwardedRef<any>) => {
  const [open, setOpen] = useState(false);

  return (
    <Input
      ref={ref}
      {...props}
      SelectProps={{
        ...SelectProps,
        open,
        MenuProps: {
          ...SelectProps?.MenuProps,
          MenuListProps: {
            ...SelectProps?.MenuProps?.MenuListProps,
            style: { ...SelectProps?.MenuProps?.MenuListProps?.style, paddingBottom: 0 },
          },
        },
        onOpen: e => {
          setOpen(true);

          if (typeof SelectProps?.onOpen === 'function') {
            SelectProps.onOpen(e);
          }
        },
        onClose: e => {
          setOpen(false);

          if (typeof SelectProps?.onClose === 'function') {
            SelectProps.onClose(e);
          }
        },
      }}
    >
      {Array.isArray(children)
        ? [...children, <CloseButton key="button" setOpen={setOpen} />]
        : [children, <CloseButton key="button" setOpen={setOpen} />]}
    </Input>
  );
});

export default Select;
