import React from 'react';
import { Alert, CircularProgress, Typography } from '@mui/material';
import AddCircleOutlineRoundedIcon from '@mui/icons-material/AddCircleOutlineRounded';
import { FormikErrors, FormikTouched, FieldArray, FormikProvider, FormikProps } from 'formik';
import dayjs, { Dayjs } from 'dayjs';
import * as Yup from 'yup';

import dateMask from '../../utils/date';
import { DropdownsData } from '../../data-hooks/profile-dropdowns/api';
import Input, { selectRenderValue } from '../ui-kit/Input';
import Select from '../ui-kit/Select';
import Button from '../ui-kit/Button';
import MenuItem from '../ui-kit/MenuItem';
import DatePicker, { getActionBar } from '../ui-kit/DatePicker';
import OccasionFormFields, { OccasionValue } from './OccasionFormFields';

export interface ProfileValues {
  name: string;
  gender: string;
  birthday: Dayjs | null;
  country: string;
  hobbies: string[];
  interests: string[];
  occasions: OccasionValue[];
}

export interface Props {
  title: string;
  values: ProfileValues;
  touched: FormikTouched<ProfileValues>;
  errors: FormikErrors<ProfileValues>;
  loading: boolean;
  isValid: boolean;
  formikState: FormikProps<ProfileValues>;
  generalError: string;
  submitBtnLabel?: string;
  dropdowns: DropdownsData;
  showOccasionFields?: boolean;
  handleChange(e: React.ChangeEvent<any>): void;
  handleBlur(e: React.FocusEvent<any, Element>): void;
  handleSubmit(e?: React.FormEvent<HTMLFormElement>): void;
}

const hobbiesLength = {
  MIN: 1,
  MAX: 10,
};
const interestsLength = {
  MIN: 1,
  MAX: 10,
};

export const profileFormValidators = {
  name: Yup.string()
    .trim()
    .required('Name is a required field')
    .min(3, ({ min }) => `Name must be longer than or equal to ${min} characters`),
  gender: Yup.string().required('Gender is a required field'),
  birthday: Yup.object().required('Birthday is a required field'),
  country: Yup.string().required('Country is a required field'),
  hobbies: Yup.array()
    .required('Hobbies is a required field')
    .min(hobbiesLength.MIN, ({ min }) => `Please select ${min} to ${hobbiesLength.MAX} hobbies`)
    .max(hobbiesLength.MAX, ({ max }) => `Please select ${hobbiesLength.MIN} to ${max} hobbies`),
  interests: Yup.array()
    .required('Interests is a required field')
    .min(interestsLength.MIN, ({ min }) => `Please select ${min} to ${interestsLength.MAX} interests`)
    .max(interestsLength.MAX, ({ max }) => `Please select ${interestsLength.MIN} to ${max} interests`),
  occasions: Yup.array().of(
    Yup.object()
      .shape({
        label: Yup.string(),
        date: Yup.object().nullable(),
      })
      .test((value, context) => {
        if ((value.label && value.date) || (!value.label && !value.date)) {
          return true;
        }

        if (!value.label) {
          return new Yup.ValidationError('Occasion Type is a required field', value.date, `${context.path}.label`);
        }
        if (!value.date) {
          return new Yup.ValidationError('Occasion Date is a required field', value.date, `${context.path}.date`);
        }

        return true;
      }),
  ),
};

const ProfileForm: React.FC<Props> = (props: Props) => {
  const {
    title,
    values,
    touched,
    errors,
    loading,
    isValid,
    formikState,
    generalError,
    dropdowns,
    submitBtnLabel = 'Create',
    showOccasionFields = true,
    handleSubmit,
    handleChange,
    handleBlur,
  } = props;
  const { gender: genders, countries, hobbies, interests, occasions } = dropdowns;

  const onDateChange = (name: string) => async (value: Dayjs | null) => {
    await formikState.setFieldValue(name, value, true);
    await formikState.setFieldTouched(name);

    if (name === 'birthday') {
      const birthdayOccasionIndex = values.occasions.findIndex(({ label }) => label.toLowerCase() === name);
      if (birthdayOccasionIndex !== -1) {
        await formikState.setFieldValue(`occasions[${birthdayOccasionIndex}].date`, value, true);
        await formikState.setFieldTouched(`occasions[${birthdayOccasionIndex}].date`);
      } else {
        await formikState.setFieldValue(`occasions`, [{ label: 'Birthday', date: value }, ...values.occasions], true);
        await formikState.setFieldTouched(`occasions[0].label`, true);
        await formikState.setFieldTouched('occasions[0].date', true);
      }
    }
  };

  return (
    <FormikProvider value={formikState}>
      <form className="flex flex-col gap-1 w-full" onSubmit={handleSubmit}>
        <Typography variant="h5" className="text-center pb-8 pt-5 text-blue-light">
          {title}
        </Typography>
        {!!generalError && (
          <div className="pb-5">
            <Alert severity="error">{generalError}</Alert>
          </div>
        )}
        <Input
          label="Name *"
          placeholder="Name *"
          name="name"
          autoComplete="off"
          value={values.name}
          InputLabelProps={{ /* shrink: true, */ margin: 'dense' }}
          error={!!(touched?.name && errors?.name)}
          helperText={touched?.name && errors?.name ? errors.name : ' '}
          onChange={handleChange}
          onBlur={handleBlur}
        />
        <Select
          select
          label="Gender *"
          name="gender"
          autoComplete="off"
          value={values.gender}
          error={!!(touched?.gender && errors?.gender)}
          helperText={touched?.gender && errors?.gender ? errors.gender : ' '}
          SelectProps={{
            displayEmpty: true,
            renderValue: selectRenderValue('Gender *'),
          }}
          onChange={handleChange}
          onBlur={handleBlur}
        >
          <MenuItem hidden className="!hidden" value="" />
          {genders.map(gender => (
            <MenuItem key={gender} value={gender}>
              {gender}
            </MenuItem>
          ))}
        </Select>
        <DatePicker
          closeOnSelect
          label="Birthday *"
          name="birthday"
          format={dateMask}
          defaultValue={dayjs().subtract(1, 'year')}
          minDate={dayjs().subtract(100, 'year')}
          maxDate={dayjs().subtract(1, 'year')}
          value={values.birthday}
          slots={{
            actionBar: getActionBar({ value: values.birthday }),
          }}
          slotProps={{
            textField: {
              placeholder: 'Birthday *',
              error: !!(touched?.birthday && errors?.birthday),
              helperText: touched?.birthday && errors?.birthday ? errors.birthday : ' ',
              onKeyDown: e => e.preventDefault(),
            },
          }}
          onAccept={onDateChange('birthday')}
          onClose={() => handleBlur({ target: { name: 'birthday' } } as any)}
        />
        <Select
          select
          label="Country *"
          name="country"
          autoComplete="off"
          value={values.country}
          error={!!(touched?.country && errors?.country)}
          helperText={touched?.country && errors?.country ? errors.country : ' '}
          SelectProps={{
            displayEmpty: true,
            renderValue: selectRenderValue('Country *'),
          }}
          onChange={handleChange}
          onBlur={handleBlur}
        >
          <MenuItem hidden className="!hidden" value="" />
          {countries.map(country => (
            <MenuItem key={country} value={country}>
              {country}
            </MenuItem>
          ))}
        </Select>
        <Select
          select
          label="Hobbies *"
          name="hobbies"
          autoComplete="off"
          value={values.hobbies}
          error={!!(touched?.hobbies && errors?.hobbies)}
          helperText={touched?.hobbies && errors?.hobbies ? errors.hobbies : ' '}
          SelectProps={{
            multiple: true,
            displayEmpty: true,
            renderValue: selectRenderValue('Hobbies *'),
          }}
          onChange={handleChange}
          onBlur={handleBlur}
        >
          <MenuItem key="" hidden className="!hidden" value="" />
          {hobbies.map(hobby => (
            <MenuItem
              key={hobby}
              value={hobby}
              disabled={values.hobbies.length >= 10 && !values.hobbies.includes(hobby)}
            >
              {hobby}
            </MenuItem>
          ))}
        </Select>
        <Select
          select
          label="Interests *"
          name="interests"
          autoComplete="off"
          value={values.interests}
          error={!!(touched?.interests && errors?.interests)}
          helperText={touched?.interests && errors?.interests ? errors.interests : ' '}
          SelectProps={{
            multiple: true,
            displayEmpty: true,
            renderValue: selectRenderValue('Interests *'),
          }}
          onChange={handleChange}
          onBlur={handleBlur}
        >
          <MenuItem hidden className="!hidden" value="" />
          {interests.map(interest => (
            <MenuItem
              key={interest}
              value={interest}
              disabled={values.interests.length >= 10 && !values.interests.includes(interest)}
            >
              {interest}
            </MenuItem>
          ))}
        </Select>
        {showOccasionFields && (
          <FieldArray
            validateOnChange
            name="occasions"
            render={arrayHelpers => (
              <div className="pt-1">
                <div className="flex flex-col gap-3 w-full">
                  {!!values.occasions?.length &&
                    values.occasions.map(({ label, date }, index: number) => {
                      const key = `${index}`;
                      return (
                        <OccasionFormFields
                          key={key}
                          label={label}
                          date={date}
                          index={index}
                          touched={touched?.occasions?.[index] || {}}
                          errors={errors?.occasions?.[index] || {}}
                          arrayHelpers={arrayHelpers}
                          showDelete={values.occasions?.length > 1}
                          selectedOccasions={values.occasions}
                          occasionTypes={occasions}
                          handleChange={handleChange}
                          handleBlur={handleBlur}
                          handleDateChange={onDateChange(`occasions.${index}.date`)}
                        />
                      );
                    })}
                </div>
                {values.occasions?.length < occasions?.length && (
                  <div className="pb-2">
                    <Button
                      type="button"
                      color="secondary"
                      variant="text"
                      startIcon={<AddCircleOutlineRoundedIcon />}
                      disabled={!!values.occasions.find(({ label, date }) => !label || !date)}
                      onClick={() => {
                        arrayHelpers.push({ label: '', date: null });
                        if (formikState.submitCount > 0) {
                          formikState.setFieldTouched(`occasions[${values.occasions.length}].label`, true);
                          formikState.setFieldTouched(`occasions[${values.occasions.length}].date`, true);
                        }
                      }}
                    >
                      Add Occasion
                    </Button>
                  </div>
                )}
              </div>
            )}
          />
        )}

        <div className="pt-2 flex justify-center">
          <Button
            type="submit"
            color="secondary"
            disabled={!isValid || loading}
            startIcon={loading ? <CircularProgress size={18} /> : null}
          >
            {submitBtnLabel}
          </Button>
        </div>
      </form>
    </FormikProvider>
  );
};

export default ProfileForm;
