import dayjs, { Dayjs } from 'dayjs';
import utc from 'dayjs/plugin/utc';

dayjs.extend(utc);

const dateMask = 'DD MMMM YYYY';

const FIXED_DATES_MAP = {
  "Valentine's Day": (now: Dayjs) => dayjs(`${now.get('year')}-02-14T00:00:00.000Z`),
  "Mother's Day": (now: Dayjs) => {
    const date = dayjs(`${now.get('year')}-05-01T00:00:00.000Z`);
    return date.set('day', 13);
  },
  "Father's Day": (now: Dayjs) => {
    const date = dayjs(`${now.get('year')}-06-01T00:00:00.000Z`);
    return date.set('day', 20);
  },
  Christmas: (now: Dayjs) => dayjs(`${now.get('year')}-12-25T00:00:00.000Z`),
};

export const fixedDates = {
  has: (key: keyof typeof FIXED_DATES_MAP) => !!FIXED_DATES_MAP[key],
  get: (key: keyof typeof FIXED_DATES_MAP) => {
    const now = dayjs().toISOString().split('T')[0];
    const today = dayjs(`${now}T00:00:00.000Z`);
    const occasionDate = FIXED_DATES_MAP[key](today);

    if (today > occasionDate) {
      return occasionDate.add(1, 'year');
    }

    return occasionDate;
  },
};

export default dateMask;
