import React, { useState } from 'react';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { PickersActionBarProps } from '@mui/x-date-pickers';
import { DatePicker as MuiDatePicker, DatePickerProps } from '@mui/x-date-pickers/DatePicker';
import { TextFieldProps } from '@mui/material/TextField';
import FormControlLabel from '@mui/material/FormControlLabel';
import dayjs, { Dayjs } from 'dayjs';
import utc from 'dayjs/plugin/utc';

import Button from '../Button';

dayjs.extend(utc);

export interface Props extends DatePickerProps<Dayjs>, React.RefAttributes<HTMLDivElement> {}

const DatePicker: React.FC<Props> = ({ label, slotProps, onClose, ...props }: Props) => {
  const [open, setOpen] = useState(false);

  const isError = (slotProps?.textField as TextFieldProps)?.error;

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <FormControlLabel
        labelPlacement="top"
        className={`!mx-0 ${isError ? 'Mui-error' : ''}`}
        label={label}
        control={
          <MuiDatePicker
            {...props}
            openTo="year"
            views={['year', 'month', 'day']}
            open={open}
            className="w-full"
            slotProps={{
              ...slotProps,
              textField: {
                ...slotProps?.textField,
                onClick: () => {
                  setOpen(true);
                },
                onFocus: e => {
                  setTimeout(() => {
                    e.target?.blur();
                  }, 100);
                },
              },
            }}
            onOpen={() => {
              setOpen(true);
            }}
            onClose={() => {
              setOpen(false);

              if (typeof onClose === 'function') {
                onClose();
              }
            }}
          />
        }
      />
    </LocalizationProvider>
  );
};

export const getActionBar =
  ({ value }: { value: Dayjs | null }): React.FC<PickersActionBarProps> =>
  ({ onAccept }: PickersActionBarProps) => {
    return (
      <div className="MuiDialogActions-root MuiDialogActions-spacing MuiPickersLayout-actionBar flex justify-end pr-4 pb-4 pt-1">
        <Button disabled={!value} onClick={onAccept}>
          Ok
        </Button>
      </div>
    );
  };

export default DatePicker;
