import { Palette, Color } from '@mui/material';
import { grey as muiGray } from '@mui/material/colors';

// light to darker
const grey: Color = {
  ...muiGray,
};

const palette: Partial<Palette> = {
  grey,
  primary: {
    main: '#ffdb73',
    light: '#ffcb8a',
    dark: '#ffb420',
    contrastText: '#004658',
  },
  secondary: {
    main: '#004658',
    light: '#00A397',
    dark: '#002638',
    contrastText: '#FFDC73',
  },
  background: {
    default: grey[100],
    paper: grey[200],
  },
  tonalOffset: 0.5,
};

export default palette;
