import React from 'react';
import MuiIconButton, { IconButtonProps } from '@mui/material/IconButton';

export interface Props extends IconButtonProps {}

const IconButton = (props: Props) => {
  return <MuiIconButton {...props} />;
};

export default IconButton;
