import axios, { AxiosError, AxiosRequestConfig, AxiosResponse } from 'axios';

export interface Auth {
  accessToken: string | null;
  handleLogout(): Promise<void>;
}
export class AuthError extends Error {}

const formatBody = (body: any): [string | FormData | null, { [key: string]: string } | null] => {
  // If multipart return FormData instance
  if (body instanceof FormData) return [body, {}];

  // If valid JSON
  if (body) return [JSON.stringify(body), { 'Content-Type': 'application/json' }];

  // [body, headers]
  return [null, null];
};

const fetch = async <T>(url: string, config: AxiosRequestConfig): Promise<AxiosResponse<T>> => {
  try {
    const [data, headers] = formatBody(config.data);
    return await axios<T>(url, { ...config, data, headers: { ...headers, ...config.headers } });
  } catch (err) {
    if (err instanceof AxiosError && err.response?.status === 401) {
      throw new AuthError(err.response?.data?.message);
    }

    throw err;
  }
};

export const fetchWithAuth = async <T>(
  auth: Auth,
  url: string,
  config: AxiosRequestConfig,
): Promise<AxiosResponse<T>> => {
  const authHeaders = {
    Authorization: `Bearer ${auth.accessToken}`,
  };

  try {
    return fetch<T>(url, { ...config, headers: { ...config.headers, ...authHeaders } });
  } catch (err: unknown | AxiosError | AuthError) {
    if (err instanceof AuthError) {
      await auth.handleLogout();
    }

    throw err;
  }
};

export default fetch;
