import React, { FocusEvent, AnimationEvent, useState, forwardRef, ForwardedRef } from 'react';
import TextField, { TextFieldProps as MuiTextFieldProps } from '@mui/material/TextField';
import FormControlLabel from '@mui/material/FormControlLabel';

type TextFieldProps = MuiTextFieldProps & { animatedLabel?: string };

const Input = forwardRef((fieldProps: TextFieldProps, ref?: ForwardedRef<any>) => {
  const { label, animatedLabel, value, error, inputProps, InputLabelProps, onFocus, onBlur, ...props } = fieldProps;
  const [autoFilled, setAutoFilled] = useState(false);
  const [focused, setFocused] = useState(false);

  const labelShrink = autoFilled || focused || (Array.isArray(value) ? !!(value as Array<any>).length : value !== '');

  const makeAnimationStartHandler = (e: AnimationEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const autofilled = !!e.currentTarget?.matches('*:-webkit-autofill');

    if (e.animationName === 'mui-auto-fill' || e.animationName === 'mui-auto-fill-cancel') {
      setAutoFilled(autofilled);
    }

    if (typeof inputProps?.onAnimationStart === 'function') {
      inputProps.onAnimationStart(e);
    }
  };

  const focus = (e: FocusEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    setFocused(true);

    if (typeof onFocus === 'function') {
      onFocus(e);
    }
  };

  const blur = (e: FocusEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    setFocused(false);

    if (typeof onBlur === 'function') {
      onBlur(e);
    }
  };

  if (animatedLabel?.length) {
    return (
      <TextField
        {...props}
        label={animatedLabel}
        value={value}
        error={error}
        inputRef={ref}
        inputProps={{ ...inputProps, onAnimationStart: makeAnimationStartHandler }}
        InputLabelProps={{ shrink: labelShrink, ...InputLabelProps }}
        onFocus={focus}
        onBlur={blur}
      />
    );
  }

  return (
    <FormControlLabel
      labelPlacement="top"
      className={`!mx-0 ${error ? 'Mui-error' : ''}`}
      label={label}
      control={
        <TextField
          {...props}
          value={value}
          error={error}
          fullWidth
          inputRef={ref}
          inputProps={{ ...inputProps, onAnimationStart: makeAnimationStartHandler }}
          InputLabelProps={{ shrink: labelShrink, ...InputLabelProps }}
          onFocus={focus}
          onBlur={blur}
        />
      }
    />
  );
});

export const selectRenderValue =
  (placeholder: string) =>
  (val: unknown): React.ReactNode => {
    if (val && (val as string | Array<string>)?.length) {
      if (Array.isArray(val)) {
        return [...val].join(', ');
      }

      /* eslint-disable-next-line react/jsx-no-useless-fragment */
      return <>{val}</>;
    }

    return <span className="opacity-40">{placeholder}</span>;
  };

export default Input;
