import React from 'react';
import MuiDialog, { DialogProps } from '@mui/material/Dialog';
import MuiDialogTitle, { DialogTitleProps as MuiDialogTitleProps } from '@mui/material/DialogTitle';
import MuiDialogContent, { DialogContentProps as MuiDialogContentProps } from '@mui/material/DialogContent';
import MuiDialogContentText, {
  DialogContentTextProps as MuiDialogContentTextProps,
} from '@mui/material/DialogContentText';
import MuiDialogActions, { DialogActionsProps as MuiDialogActionsProps } from '@mui/material/DialogActions';

export interface DialogTitleProps extends MuiDialogTitleProps {}
export const DialogTitle = (props: DialogTitleProps) => <MuiDialogTitle {...props} />;

export interface DialogContentProps extends MuiDialogContentProps {}
export const DialogContent = (props: DialogContentProps) => <MuiDialogContent {...props} />;

export interface DialogContentTextProps extends MuiDialogContentTextProps {}
export const DialogContentText = (props: DialogContentTextProps) => <MuiDialogContentText {...props} />;

export interface DialogActionsProps extends MuiDialogActionsProps {}
export const DialogActions = (props: DialogActionsProps) => <MuiDialogActions {...props} />;

export interface Props extends DialogProps {}

const Dialog = (props: Props) => {
  return <MuiDialog {...props} />;
};

export default Dialog;
