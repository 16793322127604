import React from 'react';
import MuiAccordion, { AccordionProps } from '@mui/material/Accordion';
import MuiAccordionSummary, { AccordionSummaryProps as MuiAccordionSummaryProps } from '@mui/material/AccordionSummary';
import MuiAccordionDetails, { AccordionDetailsProps as MuiAccordionDetailsProps } from '@mui/material/AccordionDetails';

export interface AccordionSummaryProps extends MuiAccordionSummaryProps {}

export const AccordionSummary = (props: AccordionSummaryProps) => {
  return <MuiAccordionSummary {...props} />;
};

export interface AccordionDetailsProps extends MuiAccordionDetailsProps {}

export const AccordionDetails = (props: AccordionDetailsProps) => {
  return <MuiAccordionDetails {...props} />;
};

export interface Props extends AccordionProps {}

const Accordion = (props: Props) => {
  return <MuiAccordion {...props} />;
};

export default Accordion;
