import React, { PropsWithChildren } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import CalendarMonthRoundedIcon from '@mui/icons-material/CalendarMonthRounded';
import GroupsRoundedIcon from '@mui/icons-material/GroupsRounded';

import { useAuth } from '../../providers/auth';
import Button from '../ui-kit/Button';
import Logo from '../ui-kit/Logo';
import IconButton from '../ui-kit/Button/IconButton';

const MainLayout: React.FC<PropsWithChildren> = ({ children }: PropsWithChildren) => {
  const { profile, handleLogout } = useAuth();
  const navigate = useNavigate();
  const { pathname } = useLocation();

  const logout = async () => {
    await handleLogout();
    return navigate('/login');
  };

  const openNotifications = () => {
    if (!pathname.startsWith('/notifications')) {
      navigate('/notifications');
    }
  };

  const openConnections = () => {
    if (pathname !== '/') {
      navigate('/');
    }
  };

  return (
    <div className="flex flex-col min-h-[100vh]">
      <div className="container">
        <div className="flex justify-end items-center pt-3 pb-2">
          <div>
            <Button color="secondary" variant="text" size="small" onClick={logout}>
              Logout
            </Button>
          </div>
        </div>
        <div className="px-12 sm:px-14">
          <Logo />
        </div>
        {(!!profile?.name || profile?.type === 'guest') && (
          <div className="flex justify-end items-center pt-4">
            <IconButton type="button" color="secondary" size="large" onClick={openConnections}>
              <GroupsRoundedIcon fontSize="large" />
            </IconButton>
            {profile?.type !== 'guest' && (
              <>
                <div className="w-[2px] h-[40px] mx-2 bg-blue-main" />
                <IconButton type="button" color="secondary" size="large" onClick={openNotifications}>
                  <CalendarMonthRoundedIcon fontSize="large" />
                </IconButton>
              </>
            )}
          </div>
        )}
      </div>
      <div className="grow flex flex-col">{children}</div>
    </div>
  );
};

export default MainLayout;
