import React, { useRef, useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { CircularProgress, Paper, Typography } from '@mui/material';
import CheckRoundedIcon from '@mui/icons-material/CheckRounded';
import dayjs from 'dayjs';

import Budget from '../../config/budget';
import isBudgetValid from '../../utils';
import dateMask from '../../utils/date';
import Button from '../../components/ui-kit/Button';
import useNotification from '../../data-hooks/notifications/useNotification';
import Input from '../../components/ui-kit/Input';
import Footer from '../../components/Footer';
import ShareButton from '../../components/ShareButton';

const Notifications: React.FC = () => {
  const navigate = useNavigate();
  const { data, loading } = useNotification({ fetchAll: true });

  const [showBudgetField, setShowBudgetField] = useState<string>('');
  const [budgetValue, setBudgetValue] = useState<string>('');

  const budgetInpRef = useRef<HTMLInputElement>();

  useEffect(() => {
    if (showBudgetField) {
      budgetInpRef.current?.focus?.();
    }
  }, [showBudgetField]);

  const getDateStr = (date: string | Date): string => {
    return dayjs(date).format(dateMask);
  };

  const openRecommends = (connectionId: string, occasionId: string) => {
    if (isBudgetValid(budgetValue)) {
      const budget = Number.parseFloat(budgetValue).toFixed(2);
      navigate(`/connections/${connectionId}?occasionId=${occasionId}&budget=${budget}`);
    }
  };

  return (
    <div className="container pt-2 pb-24">
      <div className="pt-3 pb-10 bg-white rounded-[30px] shadow-lg">
        <Typography variant="h5" className="text-center text-blue-light !font-bold !mb-4">
          Coming Up
        </Typography>
        <div>
          {!data?.length && loading && (
            <div className="flex justify-center items-center h-[30vh]">
              <CircularProgress size={40} />
            </div>
          )}
          {!!data?.length &&
            data.map(({ id, name, occasion, date, contactId }) => (
              <Paper
                key={id}
                className="mb-[2px] !bg-white !rounded-none"
                elevation={0}
                style={{ boxShadow: '0 2px 3px -1px rgba(0,0,0,.3)' }}
              >
                <div className="p-3 flex justify-between items-center gap-1 flex-wrap">
                  <Typography className="break-words !text-sm max-w-full">
                    <span className="text-blue-light font-bold !break-words max-w-full">{name}&apos;s</span>{' '}
                    <span>
                      {occasion} on {getDateStr(date)}
                      <br />
                      Find our suggestions to celebrate
                    </span>
                  </Typography>
                  <div className="shrink-0 ml-auto">
                    {id === showBudgetField ? (
                      <form
                        onSubmit={e => {
                          e.preventDefault();
                          e.stopPropagation();
                          openRecommends(contactId, id);
                        }}
                      >
                        <div className="flex gap-1">
                          <Input
                            animatedLabel="Budget"
                            name="budget"
                            type="number"
                            autoComplete="off"
                            size="small"
                            className="w-32"
                            helperText={`Min ${Budget.MIN}, Max ${Budget.MAX}`}
                            FormHelperTextProps={{ style: { margin: '0 10px' } }}
                            InputProps={{ className: 'borderDefault' }}
                            ref={budgetInpRef}
                            value={budgetValue}
                            onChange={({ target }) => setBudgetValue(target.value)}
                          />
                          <Button
                            type="submit"
                            className="!w-[40px] !min-w-[40px] !h-[40px] !p-1"
                            disabled={!isBudgetValid(budgetValue)}
                          >
                            <CheckRoundedIcon />
                          </Button>
                        </div>
                      </form>
                    ) : (
                      <Button
                        onClick={() => {
                          setShowBudgetField(id);
                          setBudgetValue('');
                        }}
                      >
                        Set Budget
                      </Button>
                    )}
                  </div>
                </div>
              </Paper>
            ))}
        </div>
      </div>
      <div className="pt-3">
        <Footer />
      </div>
      <ShareButton className="!fixed bottom-7 left-[50%] -translate-x-1/2 w-max">Share OFFRA</ShareButton>
    </div>
  );
};

export default Notifications;
